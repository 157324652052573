import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { departments } from '../../constants/departments';
import './styles/WorkerList.css';

export const WorkerList = () => {
  const navigate = useNavigate();

  const [usuarios, setUsuarios] = useState([]);
  const [calendarios, setCalendarios] = useState([]);
  const [status, setStatus] = useState('Activo');

  useEffect(() => {
    const arrUser = [];
    getDocs(query(collection(db, 'users'))).then((data) => {
      data.forEach((doc) => {
        //Añadir el año activo de las horas variables
        const userData = { ...doc.data(), activeHoursYear: new Date().getFullYear(), activeCalendarYear: new Date().getFullYear() };
        arrUser.push(userData);
      });
      arrUser.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
      setUsuarios(arrUser);
    });
  }, []);

  useEffect(() => {
    const arrCalendars = [];
    getDocs(query(collection(db, 'calendars'))).then((data) => {
      data.forEach((doc) => {
        const calendar = { id: doc.id, ...doc.data() };
        arrCalendars.push(calendar);
      });
      setCalendarios(arrCalendars);
    });
  }, []);

  const updateUser = async (e, user) => {
    e.preventDefault();
    const userData = {
      displayName: e.target[0].value,
      email: e.target[1].value,
      departamento: e.target[3].value,
      estadoSesion: e.target[4].value,
    };

    //Añade los campos del formulario que no queremos que se actualicen en tiempo real en el estado
    user.displayName = userData.displayName;
    user.email = userData.email;
    user.departamento = userData.departamento;
    user.estadoSesion = userData.estadoSesion;

    //Clona el objeto para eliminar los campos que no queremos actualizar en la bd
    const userToUpdate = { ...user };
    delete userToUpdate.activeCalendarYear;
    delete userToUpdate.activeHoursYear;

    //Actualiza el estado con los nuevos datos de user
    setUsuarios(usuarios.map((usuario) => (usuario.id === user.id ? user : usuario)));

    //Actualiza los datos en la base de datos con el objeto clonado
    await updateDoc(doc(db, 'users', user.id), userToUpdate);
  };

  document.querySelector('body').style.overflow = 'hidden';
  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='workerList'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Listado de trabajadores
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <ul class='nav nav-tabs '>
                <li class='nav-item'>
                  <span class={`nav-link d-flex align-items-center gap-2 ${status === 'Activo' ? 'active' : ''}`} onClick={() => setStatus('Activo')} aria-current='page'>
                    <i class='fa-regular fa-eye'></i>
                    Activos
                  </span>
                </li>
                <li class='nav-item'>
                  <span class={`nav-link d-flex align-items-center gap-2 ${status === 'Deshabilitado' ? 'active' : ''}`} onClick={() => setStatus('Deshabilitado')}>
                    <i class='fa-regular fa-eye-slash'></i>
                    Deshabilitados
                  </span>
                </li>
              </ul>
              <div className='list'>
                <div className='row border-bottom mx-1 mb-3 pb-2'>
                  <div className='col-md-4'>
                    <span className='fw-bold'>Empleados</span>
                  </div>
                  <div className='col-md-4'>
                    <span className='fw-bold'>Correo</span>
                  </div>
                  <div className='col-md-2'>
                    <span className='fw-bold'>Departamento</span>
                  </div>
                  <div className='col-md-2'>
                    <span className='fw-bold'>Estado</span>
                  </div>
                </div>

                {usuarios
                  .filter((user) => (status === 'Deshabilitado' ? user.estadoSesion === 'Deshabilitado' : user.estadoSesion !== 'Deshabilitado'))
                  .map((user) => (
                    <div className='worker row shadow-sm'>
                      <div
                        className='col-md-12 d-flex align-items-center px-3 py-2'
                        data-bs-toggle='collapse'
                        data-bs-target={'#' + user.displayName.replace(/\s/g, '-')}
                        aria-expanded='false'
                        aria-controls='employee'
                      >
                        <div className='col-md-4 d-flex gap-4 align-items-center'>
                          <img src={user.photoURL} alt='Avatar' className='avatar' />
                          <p className='float-start fw-bold '>{user.displayName}</p>
                        </div>
                        <div className='col-md-4'>
                          <p className='float-start fw-semibold '>{user.email}</p>
                        </div>
                        <div className='col-md-2'>
                          <p className='float-start fw-semibold '>{user.departamento}</p>
                        </div>
                        <div className='col-md-2'>
                          <p className={`float-start fw-semibold ${user.estadoSesion}`}>{user.estadoSesion}</p>
                        </div>
                      </div>
                      <div className='collapse' id={user.displayName.replace(/\s/g, '-')}>
                        <hr className='my-4' />
                        <div>
                          <form onSubmit={(e) => updateUser(e, user)} key={user.id}>
                            <div className='row'>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='name' className='form-label'>
                                  Nombre
                                </label>
                                <input type='text' className='form-control' id='name' defaultValue={user.displayName} required />
                              </div>
                              <div className='mb-3 col-md-8'>
                                <label htmlFor='email' className='form-label'>
                                  Dirección de correo
                                </label>
                                <input type='email' className='form-control' id='email' defaultValue={user.email} required />
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='initials' className='form-label'>
                                  Iniciales
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='initials'
                                  defaultValue={user.iniciales}
                                  required
                                  onChange={(e) => setUsuarios(usuarios.map((usuario) => (usuario.id === user.id ? { ...usuario, iniciales: e.target.value } : usuario)))}
                                />
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='departament' className='form-label'>
                                  Departamento
                                </label>
                                <select className='form-select' id='departament' aria-label='Floating label select example' required>
                                  {departments.map((department) => (
                                    <option key={department} value={department} selected={user.departamento === department ? true : false}>
                                      {department}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='estado' className='form-label'>
                                  Estado
                                </label>
                                <select className='form-select' id='estado' aria-label='Floating label select example 2' required>
                                  <option value='Desconectado' selected={user.estadoSesion === 'Desconectado' ? true : false}>
                                    Desconectado
                                  </option>
                                  <option value='Presencial' selected={user.estadoSesion === 'Presencial' ? true : false}>
                                    Presencial
                                  </option>
                                  <option value='Teletrabajo' selected={user.estadoSesion === 'Teletrabajo' ? true : false}>
                                    Teletrabajo
                                  </option>
                                  <option value='Viaje' selected={user.estadoSesion === 'Viaje' ? true : false}>
                                    Viaje
                                  </option>
                                  <option value='Deshabilitado' selected={user.estadoSesion === 'Deshabilitado' ? true : false}>
                                    Deshabilitado
                                  </option>
                                </select>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='password' className='form-label'>
                                  Calendario
                                </label>
                                <div class='d-flex gap-0'>
                                  <select
                                    className='form-select rounded-start-2 rounded-end-0'
                                    id='departament'
                                    aria-label='Floating label select example'
                                    required
                                    onChange={(e) =>
                                      setUsuarios(
                                        usuarios.map((usuario) =>
                                          usuario.id === user.id
                                            ? {
                                                ...usuario,
                                                calendario: {
                                                  ...usuario.calendario,
                                                  [user.activeCalendarYear]: {
                                                    ...usuario.calendario[user.activeCalendarYear],
                                                    id: e.target.value,
                                                  },
                                                },
                                              }
                                            : usuario
                                        )
                                      )
                                    }
                                  >
                                    {calendarios
                                      .filter((calendar) => calendar.year === user.activeCalendarYear.toString())
                                      .map((calendar) => (
                                        <option key={calendar.id} value={calendar.id} selected={user.calendario?.[user.activeCalendarYear]?.id === calendar.id ? true : false}>
                                          {calendar.name}
                                        </option>
                                      ))}
                                  </select>
                                  {user.calendario && (
                                    <select
                                      className='select-year px-3'
                                      defaultValue={user.activeCalendarYear}
                                      onChange={(e) =>
                                        setUsuarios(usuarios.map((usuario) => (usuario.id === user.id ? { ...usuario, activeCalendarYear: e.target.value } : usuario)))
                                      }
                                    >
                                      {Object.keys(user?.calendario).map((year) => (
                                        <option value={year}>{year}</option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='Horas' className='form-label'>
                                  Horas Variables
                                </label>
                                <div class='d-flex gap-0'>
                                  <input
                                    type='text'
                                    className='form-control rounded-start-2 rounded-end-0'
                                    id='Horas'
                                    value={
                                      user?.calendario?.[user.activeHoursYear]
                                        ? `${Math.floor(user?.calendario[user.activeHoursYear]?.horas_variables / 60)} : ${(
                                            user?.calendario[user.activeHoursYear]?.horas_variables % 60
                                          )
                                            .toString()
                                            .padStart(2, '0')}`
                                        : 'ERROR'
                                    }
                                    disabled={user?.calendario?.[new Date().getFullYear()] ? false : true}
                                    onChange={(e) => {
                                      //Solo permite introducir números y dos puntos
                                      e.target.value = e.target.value.replace(/[^0-9:]/g, '');
                                      setUsuarios(
                                        usuarios.map((usuario) =>
                                          usuario.id === user.id
                                            ? {
                                                ...usuario,
                                                calendario: {
                                                  ...usuario.calendario,
                                                  [user.activeHoursYear]: {
                                                    ...usuario.calendario[user.activeHoursYear],
                                                    horas_variables: e.target.value.split(':')[0] * 60 + e.target.value.split(':')[1] * 1,
                                                  },
                                                },
                                              }
                                            : usuario
                                        )
                                      );
                                    }}
                                    required
                                  />
                                  {user.calendario && (
                                    <select
                                      key={user.id + user.activeHoursYear}
                                      className='select-year px-3'
                                      defaultValue={user.activeHoursYear}
                                      onChange={(e) => setUsuarios(usuarios.map((usuario) => (usuario.id === user.id ? { ...usuario, activeHoursYear: e.target.value } : usuario)))}
                                    >
                                      {Object.keys(user?.calendario).map((year) => (
                                        <option value={year}>{year}</option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='formFile' className='form-label'>
                                  Selecciona una imagen
                                </label>
                                <input className='form-control' type='file' id='formFile' />
                              </div>
                              <div className='mb-3 col-md-12'>
                                <label htmlFor='Observaciones' className='form-label'>
                                  Observaciones
                                </label>
                                <textarea
                                  className='form-control'
                                  id='Observaciones'
                                  rows='6'
                                  onChange={(e) => setUsuarios(usuarios.map((usuario) => (usuario.id === user.id ? { ...usuario, observaciones: e.target.value } : usuario)))}
                                >
                                  {user.observaciones}
                                </textarea>
                              </div>
                              <div className='mb-3 col-md-12'>
                                <button type='submit' className='btn btn-default' data-bs-toggle='collapse' data-bs-target={'#' + user.displayName.replace(/\s/g, '-')}>
                                  Confirmar
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
